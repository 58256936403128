import 'react-app-polyfill/ie9'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'
import './reset.css'
import './fonts.css'
// import registerServiceWorker from './registerServiceWorker'

ReactDOM.render(<App/>, document.getElementById('root'))
// registerServiceWorker()
